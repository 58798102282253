import React from 'react';
import Translation from '../translation/Translation';

function TryNow() {
  return (
    <Translation></Translation>
  );
}

export default TryNow;
